import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Reveal, Animation } from 'react-genie'
import RubberBand from 'react-reveal/RubberBand'

import { Image } from '../../common/Image'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

const mapSpImageSrc = require('../../../images/map_sp.jpg')
const mapPcImageSrc = require('../../../images/map.jpg')

export const Map = () => {
  const [isMiyotaActive, setIsMiyotaActive] = useState(false)
  const [isKariyadoActive, setIsKariyadoActive] = useState(false)
  const [isNakakaruizawaActive, setIsNakakaruizawaActive] = useState(false)
  const [isHontenActive, setIsHontenActive] = useState(false)
  const [isDrugsActive, setIsDrugsActive] = useState(false)

  const onMouseMiyota = () => setIsMiyotaActive(!isMiyotaActive)
  const onMouseKariyado = () => setIsKariyadoActive(!isKariyadoActive)
  const onMouseNakakaruizawa = () => setIsNakakaruizawaActive(!isNakakaruizawaActive)
  const onMouseHonten = () => setIsHontenActive(!isHontenActive)
  const onMouseDrugs = () => setIsDrugsActive(!isDrugsActive)

  return (
    <Container>
      <SpOnly>
        <MapWrapper>
          <img src={mapSpImageSrc} alt={mapSpImageSrc} />
        </MapWrapper>
      </SpOnly>
      <PcOnly>
        <img src={mapPcImageSrc} alt={mapPcImageSrc} />
      </PcOnly>
      <Link to='/store#miyota'>
        <Miyota onMouseEnter={onMouseMiyota} onMouseLeave={onMouseMiyota}>
          <SpOnly>
            <Reveal delay={500} animation={Animation.FadeInUp}>
              <RubberBand delay={550}>
                <MiyotaBalloon>
                  <div>御代田薬局</div>
                </MiyotaBalloon>
              </RubberBand>
            </Reveal>
          </SpOnly>
          <PcOnly>
            {isMiyotaActive && <StoreBalloon direction="bottom">
              <StoreBalloonIconWrapper>
                <Image path="map_balloon_logo@2x.png" />
              </StoreBalloonIconWrapper>
              <div>御代田薬局</div>
            </StoreBalloon>}
          </PcOnly>
          <Reveal delay={500} animation={Animation.FadeInUp}>
            <RubberBand delay={550}>
              <LocationIconWrapper>
                <Image path="location_store@2x.png" />
              </LocationIconWrapper>
            </RubberBand>
          </Reveal>
        </Miyota>
      </Link>

      <Link to='/store#kariyado'>
        <Kariyado onMouseEnter={onMouseKariyado} onMouseLeave={onMouseKariyado}>
          <Reveal delay={1000} animation={Animation.FadeInUp}>
            <RubberBand delay={1050}>
              <LocationIconWrapper>
                <Image path="location_store@2x.png" />
              </LocationIconWrapper>
            </RubberBand>
          </Reveal>
          <SpOnly>
            <Reveal delay={1000} animation={Animation.FadeInUp}>
              <RubberBand delay={1050}>
                <KariyadoBalloon>
                  <div>借宿薬局</div>
                </KariyadoBalloon>
              </RubberBand>
            </Reveal>
          </SpOnly>
          <PcOnly>
            {isKariyadoActive && <StoreBalloon direction="top">
              <StoreBalloonIconWrapper>
                <Image path="map_balloon_logo@2x.png" />
              </StoreBalloonIconWrapper>
              <div>借宿薬局</div>
            </StoreBalloon>}
          </PcOnly>
        </Kariyado>
      </Link>

      <Link to='/store#nakakaruizawa'>
        <Nakakaruizawa onMouseEnter={onMouseNakakaruizawa} onMouseLeave={onMouseNakakaruizawa}>
          <Reveal delay={1500} animation={Animation.FadeInUp}>
            <RubberBand delay={1550}>
              <LocationIconWrapper>
                <Image path="location_store@2x.png" />
              </LocationIconWrapper>
            </RubberBand>
          </Reveal>
          <SpOnly>
            <Reveal delay={1500} animation={Animation.FadeInUp}>
              <RubberBand delay={1550}>
                <NakakaruizawaBalloon>
                  <div>中軽井沢薬局</div>
                </NakakaruizawaBalloon>
              </RubberBand>
            </Reveal>
          </SpOnly>
          <PcOnly>
            {isNakakaruizawaActive && <StoreBalloon direction="top">
              <StoreBalloonIconWrapper>
                <Image path="map_balloon_logo@2x.png" />
              </StoreBalloonIconWrapper>
              <div>中軽井沢薬局</div>
            </StoreBalloon>}
          </PcOnly>
        </Nakakaruizawa>
      </Link>

      <Link to='/store#shinbidodrugs'>
        <Drugs onMouseEnter={onMouseDrugs} onMouseLeave={onMouseDrugs}>
          <Reveal delay={2000} animation={Animation.FadeInUp}>
            <RubberBand delay={2050}>
              <LocationIconWrapper>
                <Image path="location_store@2x.png" />
              </LocationIconWrapper>
            </RubberBand>
          </Reveal>
          <SpOnly>
            <Reveal delay={2000} animation={Animation.FadeInUp}>
              <RubberBand delay={2050}>
                <DrugsBalloon>
                  <div>ドラッグス</div>
                </DrugsBalloon>
              </RubberBand>
            </Reveal>
          </SpOnly>
          <PcOnly>
            {isDrugsActive && <StoreBalloon direction="top">
              <StoreBalloonIconWrapper>
                <Image path="map_balloon_logo@2x.png" />
              </StoreBalloonIconWrapper>
              <div>ドラッグス</div>
            </StoreBalloon>}
          </PcOnly>
        </Drugs>
      </Link>

      <Link to='/store#karuizawahonten'>
        <Karuizawahonten onMouseEnter={onMouseHonten} onMouseLeave={onMouseHonten}>
          <Reveal delay={2500} animation={Animation.FadeInUp}>
            <RubberBand delay={2550}>
              <LocationIconWrapper>
                <Image path="location_store@2x.png" />
              </LocationIconWrapper>
            </RubberBand>
          </Reveal>
          <SpOnly>
            <Reveal delay={2500} animation={Animation.FadeInUp}>
              <RubberBand delay={2550}>
                <HontenBalloon>
                  <div>軽井沢本店</div>
                </HontenBalloon>
              </RubberBand>
            </Reveal>
          </SpOnly>
          <PcOnly>
            {isHontenActive && <StoreBalloon direction="top">
              <StoreBalloonIconWrapper>
                <Image path="map_balloon_logo@2x.png" />
              </StoreBalloonIconWrapper>
              <div>軽井沢本店</div>
            </StoreBalloon>}
          </PcOnly>
        </Karuizawahonten>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  position: relative;
`

const MapWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    img {
      width: 100%;
    }
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Miyota = styled.div`
  ${customMedia.lessThan("medium")`
    left: 5%;
    top: 67%;
    cursor: pointer;
    position: absolute;
  `}
  ${customMedia.greaterThan("medium")`
    left: 9%;
    top: 63%;
    cursor: pointer;
    position: absolute;
  `}
`

const Kariyado = styled.div`
  ${customMedia.lessThan("medium")`
    left: 45%;
    top: 29%;
    cursor: pointer;
    position: absolute;
  `}
  ${customMedia.greaterThan("medium")`
    left: 44%;
    top: 20%;
    cursor: pointer;
    position: absolute;
  `}
`

const Nakakaruizawa = styled.div`
  ${customMedia.lessThan("medium")`
    left: 61%;
    top: 26%;
    cursor: pointer;
    position: absolute;
  `}
  ${customMedia.greaterThan("medium")`
    left: 59%;
    top: 16%;
    cursor: pointer;
    position: absolute;
  `}
`

const Drugs = styled.div`
  ${customMedia.lessThan("medium")`
    right: 9%;
    top: 33%;
    cursor: pointer;
    position: absolute;
  `}
  ${customMedia.greaterThan("medium")`
    right: 13%;
    top: 24%;
    cursor: pointer;
    position: absolute;
  `}
`

const Karuizawahonten = styled.div`
  ${customMedia.lessThan("medium")`
    right: 1%;
    top: 20%;
    cursor: pointer;
    position: absolute;
  `}
  ${customMedia.greaterThan("medium")`
    right: 9%;
    top: 11%;
    cursor: pointer;
    position: absolute;
  `}
`

const LocationIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 13px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 40px;
  `}
`

const StoreBalloon = styled.div<{direction: string}>`
  background-color: #fff;
  border: 2px #0055A5 solid;
  border-radius: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #0055A5;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 20px;
  height: 20px;
  width: 70px;

  ${props => customMedia.greaterThan("medium")`
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    color: #0055A5;
    cursor: pointer;
    left: 50%;
    ${props.direction === 'bottom' ? 'top: -30%;' : 'top: 130%;'}
    transform: ${props.direction === 'top' ? 'translateY(0%) translateX(-50%)' : 'translateY(-100%) translateX(-50%)'};
    -webkit-transform: ${props.direction === 'top' ? 'translateY(0%) translateX(-50%)' : 'translateY(-100%) translateX(-50%)'};
    height: 130px;
    width: 130px;
    z-index: 99;

    :after, :before{
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      ${props.direction === 'top' ? 'bottom: 100%;' : 'top: 100%;'}
      left: 50%;
    }

    :after{
      border-color: rgba(255, 255, 255, 0);
      border-top-width: 20px;
      border-bottom-width: 20px;
      border-left-width: 5px;
      border-right-width: 5px;
      margin-left: -5px;
      ${props.direction === 'top' ? 'margin-bottom: -1px;' : 'margin-top: -1px;'}
      ${props.direction === 'top' ? 'border-bottom-color: #fff;' : 'border-top-color: #fff;'}
    }
    
    :before{
      border-color: rgba(0, 85, 165, 0);
      border-top-width:28px;
      border-bottom-width:28px;
      border-left-width: 7px;
      border-right-width: 7px;
      margin-left: -7px;
      ${props.direction === 'top' ? 'margin-bottom: 0px;' : 'margin-top: 0px;'}
      ${props.direction === 'top' ? 'border-bottom-color: #0055A5;' : 'border-top-color: #0055A5;'}
    }

    div {
      font-size: 1.4rem;
      // letter-spacing: 0.14em;
    }
  `}
`

const MiyotaBalloon = styled.div`
  background-color: #fff;
  border: 2px #0055A5 solid;
  border-radius: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #0055A5;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 20px;
  height: 20px;
  width: 70px;
  top: -8px;
  left: 23px;

  ${customMedia.lessThan("medium")`
    :after, :before{
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      right: 97%;
      top: 50%;
    }

    :after {
      border-color: rgba(255, 255, 255, 0);
      border-top-width:2px;
      border-bottom-width:2px;
      border-left-width:10px;
      border-right-width:10px;
      margin-top: -2px;
      border-right-color:#FFFFFF;
    }
    :before {
      border-color: rgba(0, 85, 165, 0);
      border-top-width:4px;
      border-bottom-width:4px;
      border-left-width:15px;
      border-right-width:15px;
      margin-top: -4px;
      margin-right: 2px;
      border-right-color:#0055A5;
    }
  `}
`

const KariyadoBalloon = styled.div`
  background-color: #fff;
  border: 2px #0055A5 solid;
  border-radius: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #0055A5;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 20px;
  height: 20px;
  width: 70px;
  top: -42px;
  left: -47px;

  ${customMedia.lessThan("medium")`
    :after, :before{
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      top:95%;
      left:76%;
    }
    :after{
        border-color: rgba(255, 255, 255, 0);
        border-top-width:10px;
        border-bottom-width:10px;
        border-left-width:3px;
        border-right-width:3px;
        margin-left: -3px;
        border-top-color:#FFFFFF;
    }
    :before{
        border-color: rgba(0, 85, 165, 0);
        border-top-width:15px;
        border-bottom-width:15px;
        border-left-width:5px;
        border-right-width:5px;
        margin-left: -5px;
        margin-top: 2px;
        border-top-color:#0055A5;
    }
  `}
`

const NakakaruizawaBalloon = styled.div`
  background-color: #fff;
  border: 2px #0055A5 solid;
  border-radius: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #0055A5;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 20px;
  height: 20px;
  width: 70px;
  top: 12px;
  left: -40px;

  ${customMedia.lessThan("medium")`
    :after,:before{
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      bottom:95%;
      left:65%;
    }
    :after{
      border-color: rgba(255, 255, 255, 0);
      border-top-width:10px;
      border-bottom-width:10px;
      border-left-width:3px;
      border-right-width:3px;
      margin-left: -3px;
      border-bottom-color:#FFFFFF;
    }
    :before{
      border-color: rgba(0, 85, 165, 0);
      border-top-width:15px;
      border-bottom-width:15px;
      border-left-width:5px;
      border-right-width:5px;
      margin-left: -5px;
      margin-bottom: 2px;
      border-bottom-color:#0055A5;
    }
  `}
`

const DrugsBalloon = styled.div`
  background-color: #fff;
  border: 2px #0055A5 solid;
  border-radius: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #0055A5;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 20px;
  height: 20px;
  width: 70px;
  top: 12px;
  right: -30px;

  ${customMedia.lessThan("medium")`
    :after,:before{
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      bottom:95%;
      left:50%;
    }
    :after{
      border-color: rgba(255, 255, 255, 0);
      border-top-width:10px;
      border-bottom-width:10px;
      border-left-width:3px;
      border-right-width:3px;
      margin-left: -3px;
      border-bottom-color:#FFFFFF;
    }
    :before{
      border-color: rgba(0, 85, 165, 0);
      border-top-width:15px;
      border-bottom-width:15px;
      border-left-width:5px;
      border-right-width:5px;
      margin-left: -5px;
      margin-bottom: 2px;
      border-bottom-color:#0055A5;
    }
  `}
`

const HontenBalloon = styled.div`
  background-color: #fff;
  border: 2px #0055A5 solid;
  border-radius: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #0055A5;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  text-align: center;
  line-height: 20px;
  height: 20px;
  width: 70px;
  top: -38px;
  right: -5px;

  ${customMedia.lessThan("medium")`
    :after, :before{
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      top:95%;
      left:76%;
    }
    :after{
        border-color: rgba(255, 255, 255, 0);
        border-top-width:10px;
        border-bottom-width:10px;
        border-left-width:3px;
        border-right-width:3px;
        margin-left: -3px;
        border-top-color:#FFFFFF;
    }
    :before{
        border-color: rgba(0, 85, 165, 0);
        border-top-width:15px;
        border-bottom-width:15px;
        border-left-width:5px;
        border-right-width:5px;
        margin-left: -5px;
        margin-top: 2px;
        border-top-color:#0055A5;
    }
  `}
`

const StoreBalloonIconWrapper = styled.div`
  margin: 20px auto 4px;
  width: 60px;
`